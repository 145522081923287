import { Clone, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

export function TriangleTree(props) {
    const { nodes } = useGLTF('/models/tree4.glb');

    const stdmat = new THREE.MeshStandardMaterial({
        color: 0x592400,
        ToneMapping: false,
    });
    nodes.Cone.material = stdmat;
    nodes.Cylinder.material = stdmat;

    return (
        <group {...props} dispose={null}>
            <group position={[2.6, -0.6, 3.5]} scale={0.17}>
                <Clone castShadow receiveShadow object={nodes.Cone} />
                <Clone castShadow receiveShadow object={nodes.Cylinder} />
            </group>
            //model5
            <group position={[33.7, -0.6, 2]} scale={0.17}>
                <Clone castShadow receiveShadow object={nodes.Cone} />
                <Clone castShadow receiveShadow object={nodes.Cylinder} />
            </group>
            <group position={[34, -0.7, 3]} scale={0.14}>
                <Clone castShadow receiveShadow object={nodes.Cone} />
                <Clone castShadow receiveShadow object={nodes.Cylinder} />
            </group>
            {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cone.geometry}
        material={materials["Material.002"]}
        position={[0, 2.59, -0.02]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.002"]}
        position={[0.01, -0.32, -0.02]}
        scale={0.19}
      /> */}
        </group>
    );
}

useGLTF.preload('/models/triangle-tree.glb');
