import "../../globalstyles.css"
import "../../App.css"
import "./AboutPage.css";

export default function AboutPage() {
    return (
        <div className='AboutPage' id="about-scroll">

            <div className="AboutPage-Text">

                <h1>what is a hackathon?</h1>

                <p className='AboutPage-description'>
                    Hackathons are a unique opportunity for
                    those interested in software, hardware, and
                    design to collaborate in a sprint-like
                    event, creating their own work and projects
                    from scratch. Hackers will compete in teams
                    to develop software and hardware solutions
                    to real world problems they face, to show
                    off cool concepts, or just to build things
                    for fun! At the end of the hackathon, teams
                    will have the chance to submit and present
                    their work to judges, who will provide
                    feedback and even award prizes based off
                    certain criteria.
                </p>

                <p className='AboutPage-description'>
                    Hack@Brown will be an overnight hackathon
                    for college students, not just from Brown
                    but from all around, to come together and
                    spend the weekend learning new skills and
                    building exciting projects! This year, we
                    will still be offering our design track,
                    where participants can learn about the
                    design process, from creating initial
                    wireframes to eventually designing a
                    high-fidelity mockup.
                </p>
                <p className='AboutPage-description'>
                    While hackathons are competitive in
                    nature, we want Hack@Brown to be centered
                    around learning as much as possible. We'll
                    be hosting numerous workshops and talks
                    during the event for everyone to have the
                    opportunity to learn more about specific
                    topics.
                </p>
            </div>
        </div >

    )
}
