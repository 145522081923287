import { Suspense } from "react"
import { RoundTree } from "./3DObjects/RoundTree"
import { ChristmasTree } from "./3DObjects/ChristmasTree"
import { TriangleTree } from "./3DObjects/TriangleTree"

export default function Trees()
{
    return (
        <Suspense>
            <RoundTree/>
            <ChristmasTree />
            <TriangleTree/>
        </Suspense>
    )
        
}