import "./Navbar.css";
function Hamburger() {


  return (
    <div className='hamburger' >
      <div className="burger burger1" />
      <div className="burger burger2" />
      <div className="burger burger1" />
    </div>

  )
}

export default Hamburger;