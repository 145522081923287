import React from 'react'
import "../../globalstyles.css"
import "../../App.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {Element} from 'react-scroll';

import './FAQPage.css'
import FAQItem from './component/FAQItem';


export default function FAQPageText() {
  return (

    <div className="faq" id="faq-scroll">


    <div className='faq-container'>
            <div className='header-class'>
                <h1>faq</h1>

            </div>
           
            <div className='parent'>
                <div className='child'>
                    <div className='faq-items'>
                        <FAQItem
                            question="I wasn't able to register and RSVP! Can I still participate?"
                            info="Yes! On the first day of the hackathon, you can come during the
                            check-in period of the event and put your information down in the standby
                            line. If registered hackers are unable to make it, we'll accept standby
                            hackers in order of sign-up. Unfortunately, we cannot guarantee admission
                            or swag for standby hackers."
                        /> 
                        <FAQItem 
                            question="Will the hackathon be in-person?"
                            info="Based on the current guidelines at Brown, we do plan 
                            on returning to an in-person event! Unfortunately, we won't be able to
                            reimburse any travel fees, but if that isn't an issue for you, we'd love
                            to see you in Providence for Hack@Brown!"
                        />
                        <FAQItem 
                            question="How do teams work?"
                            info="You’re welcome to have up to four people on a team during the hackathon! 
                            Don’t worry if you don’t have a team yet — we’ll have some fun team-forming 
                            sessions and additional opportunities for you to find an awesome group of 
                            people to work with."
                        />
                        <FAQItem 
                            question="Do we have to submit a project if we participate?"
                            info="You are not required to submit a project to
                            participate, but we encourage everyone to work on a project and try to submit to Devpost at the end of the hackathon!"
                        />
                        <FAQItem 
                            question="I’m interested in sponsoring Hack@Brown -
                            who can I contact?"
                            info={<p> We are so excited to have you join us! You
                                can email{' '}
                                <b>{' '}
                                    <a id="sponsor-email" href="mailto:sponsor@hackatbrown.org">
                                        sponsor@hackatbrown.org
                                    </a>
                                </b>{' '}
                                and we will email you ASAP!</p>}
                        />
                        <FAQItem 
                            question="Who can attend?"
                            info="All undergraduate and Masters students are
                            welcome. If you're a recent graduate or PhD student,
                            we'd love to have you as a mentor instead!"
                        />
                        <FAQItem 
                            question="How will tracks and prizes work?"
                            info="We will have a variety of sponsor tracks and
                            hackathon-specific tracks, all with their own prizes!
                            Some of our hackathon-specific tracks include Climate,
                            Community-Building, Design (no code required!), and
                            Hardware. For the Hardware track, hardware will be available
                            for hackers in our hardware booth (Sayles 105)."
                        />
                        <FAQItem 
                            question="What events will be held?"
                            info="More details regarding specific logistics will be coming 
                            soon, but you can look forward to a variety of events 
                            including panels, chats with our amazing sponsors, 
                            keynote speakers, and workshops!"
                        />
                        <FAQItem 
                            question="How much is this going to cost me?"
                            info="Hack@Brown is completely free, including
                            merch, coffee chats with sponsors, great
                            talks with tech leads, and an unforgettable
                            experience!"
                        />
                        <FAQItem
                            question="Can I volunteer to mentor?"
                            info={<p> Upperclassmen, graduate students, and alumni/industry professionals
                                are all eligible to volunteer as mentors! More details on logistics
                                are coming soon, but please fill out our{' '}
                                <b>{' '}
                                    <a id="mentor-form" href="https://forms.gle/y8CRTowbwWkC341m9">
                                        mentor interest form
                                    </a>
                                </b>{' '}
                                to
                                let us know you're interested. All mentors will receive swag as a
                                token of our appreciation! </p>} 
                        />
                        <FAQItem 
                            question="My question is not answered here!"
                            info={<p> We’re here for you! Send us an email at{' '}
                                <b> {' '}
                                    <a id="hello-email" href="mailto:hello@hackatbrown.org">
                                        hello@hackatbrown.org
                                    </a>
                                </b>{' '}
                                or reach out to us on Facebook, Twitter, and
                                Instagram! </p>} 
                        />
                    </div>                   
                </div>
            </div>
        </div>
    </div>
  )
}