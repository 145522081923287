import { useFrame, useThree } from '@react-three/fiber';
import {EffectComposer, Vignette} from "@react-three/postprocessing"
import { useHelper } from '@react-three/drei';
import Model from './Model1';
import Model2 from './Model2';
import Model3 from './Model3';
import Model4 from './Model4';
import Model5 from './Model5';
import Trees from './Trees';
// import { useRef } from "react"
import * as THREE from 'three';

import { useEffect, useRef, useState } from 'react';
import { useScroll, ScrollControls } from '@react-three/drei';

var vec = new THREE.Vector3(0, 0, 0); // create once and reuse
var pos = new THREE.Vector3(-2, 0, 0); // create once and reuse
var followerPos = new THREE.Vector3(-2, 0, 0);
var direction = new THREE.Vector3(1, 0, 0);
var velocity = new THREE.Vector3(0, 0, 0);

// ease function for linear interpolation
function easeInOutExpo(x) {
    return x === 0
        ? 0
        : x === 1
        ? 1
        : x < 0.5
        ? Math.pow(2, 20 * x - 10) / 2
        : (2 - Math.pow(2, -20 * x + 10)) / 2;
}

export default function Scene({
    setShowNav,
    showGraphics,
    setShowSetGraphicsButton,
}) {
    const { camera, scene } = useThree();
    const directionalLight1 = useRef();
    const pointLight = useRef();
    const follower = useRef();
    // useHelper(directionalLight1, THREE.DirectionalLightHelper, 1)
    // useHelper(pointLight, THREE.SpotLightHelper, 1)
    // directionalLight2.current.current.lookAt(16, 0, 0)
    const scrollStep = (0.004 / 1440) * window.innerWidth;

    const [mousePos, setMousePos] = useState({});

    const lightColor = '#faede1';
    const lightTarget1 = new THREE.Mesh();
    const lightTarget2 = new THREE.Mesh();

    const startDarkColor = new THREE.Color(0x472515);
    const middleOrangeColor = new THREE.Color(0xcf8b34);
    const endLightColor = new THREE.Color(0xface86);

    // bottom plane data
    const planeMaterial = new THREE.MeshStandardMaterial({
        color: 0x472515,
    });
    const planeGeometry = new THREE.PlaneGeometry(100, 100);
    const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);
    planeMesh.position.set(-1, -1, -1);
    planeMesh.rotation.x = -Math.PI * 0.5;
    planeMesh.receiveShadow = true;
    planeMesh.scale.set(500, 500, 500);
    scene.add(planeMesh);

    // fog
    const fogbg = new THREE.Fog(0x472515, 6, 15);
    scene.fog = fogbg;

    useEffect(() => {
        // directionalLight2.current.lookAt([16, 0, 0])
        // console.log(directionalLight2.current.lookAt)

        const handleMouseMove = (event) => {
            setMousePos({ x: event.screenX, y: event.screenY });
            vec.set(
                (event.clientX / window.innerWidth) * 2 - 1,
                -(event.clientY / window.innerHeight) * 2 + 1,
                0.001
            );

            vec.unproject(camera);

            vec.sub(camera.position).normalize();

            var distance = 5;

            pos.copy(camera.position).add(vec.multiplyScalar(distance));
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const calcColorGradient = (state) => {
        const xPos = state.camera.position.x;

        // don't change color for the first part of the scene
        if (xPos < 15.0) {
            planeMaterial.color.copy(startDarkColor);
            return;
        }

        if (xPos < 35.0) {
            let alpha = (xPos - 15.0) / 20.0;
            alpha = Math.min(Math.max(alpha, 0.0), 1.0);

            scene.background
                .copy(startDarkColor)
                .lerp(middleOrangeColor, easeInOutExpo(alpha));
        } else {
            let alpha = (xPos - 35.0) / 20.0;
            alpha = Math.min(Math.max(alpha, 0.0), 1.0);

            scene.background.copy(middleOrangeColor).lerp(endLightColor, alpha);
        }
        planeMaterial.color.copy(scene.background);
        state.scene.fog.color.copy(scene.background);
    };

    useFrame((state) => {
        // console.log(state.camera.rotation.y);
        directionalLight1.current.position.x =
            scrollStep * window.scrollX - 5.0;
        lightTarget1.position.x = scrollStep * window.scrollX;
        // directionalLight1.current.lookAt([scrollStep*window.scrollX, 1, 3]);
        // directionalLight1.current.position.target = [scrollStep*window.scrollX, 1, 3]
        state.camera.position.x = scrollStep * window.scrollX;
        state.camera.rotation.y =
            -(mousePos.x / window.innerWidth - 0.5) * 0.03;
        state.camera.rotation.x =
            -(mousePos.y / window.innerWidth - 1.0) * 0.03;
        state.camera.rotation.z = 0;

        // if (progress > 50){
        // follower.current.position.z = pos.z;
        // followerPos.z = pos.z;

        // velocity.copy(pos).sub(followerPos).normalize();

        // const angle = velocity.angleTo(new THREE.Vector3(1, 0, 0));

        // if (pos.y > followerPos.y) follower.current.rotation.z = angle;
        // else follower.current.rotation.z = -angle;

        // //(velocity - direction).normalize() * 0.0? + direction
        // direction.copy(
        //     velocity.sub(direction).clampLength(0, 0.02).add(direction)
        // );
        // // console.log(direction)

        // followerPos.add(direction.clone().multiplyScalar(0.02));
        // // console.log(velocity)
        // // followerPos.copy(pos)
        // follower.current.position.x = followerPos.x;
        // follower.current.position.y = followerPos.y;
        // }

        // follower.current.position.x = pos.x;
        // follower.current.position.y = pos.y;
        // follower.current.position.z = pos.z;
        // follower.current.position = pos
        // console.log(follower)

        // gradient transition
        calcColorGradient(state);

        // choose when to set the nav bar to be visible, light, or dark
        const navBarState =
            state.camera.position.x > 50.0
                ? 2
                : state.camera.position.x > 25.0
                ? 1
                : 0;
        setShowNav(navBarState);

        // show the hide graphics button in first part of scene
        setShowSetGraphicsButton(state.camera.position.x < 8.0);
    });
    // const brown = new Three.Color("brown")

    return (
        <>
            <EffectComposer>
                <Vignette/>
            </EffectComposer>
            <fog attach="fog" args={['#472515', 6, 15]} />
            <color attach="background" args={['#472515']} />
            <primitive object={lightTarget1} position={[0, 0, 0]} />
            <directionalLight
                ref={directionalLight1}
                castShadow
                color={lightColor}
                position={[-5, 5, 10]}
                target={lightTarget1}
                intensity={1.3}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            <primitive object={lightTarget2} position={[7.3, 0, 1]} />
            <spotLight
                ref={pointLight}
                target={lightTarget2}
                position={[7.3, -1.2, 1.3]}
                color={'#ffc466'}
                intensity={5}
            />
            {/* <directionalLight
        ref={directionalLight2}
        castShadow
        color = {lightColor}
        position={[15,1,3]}
        // lookAt={[16, 100, 0]}
        // target-x = {16}
        // intensity={1.5}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10} />
        <ambientLight color={lightColor} intensity={0.8}/> */}
            <ambientLight color={lightColor} intensity={0.8} />
            {/* <mesh castShadow receiveShadow position-x={2}>
            <boxGeometry/>
            <meshStandardMaterial color="mediumpurple"/>
        </mesh> */}

            {/* <mesh ref={sphere} scale={0.1}>
          <sphereGeometry/>
          <meshBasicMaterial/>
        </mesh> */}
            {/* <pointLight ref={follower} intensity={1}/> */}

            {showGraphics && (
                <>
                    <Model />
                    <Model2 />
                    <Model3 />
                    <Model4 />
                    <Model5 />
                    <Trees />
                </>
            )}
        </>
    );
}
