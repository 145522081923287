import "../../globalstyles.css";
import "../../App.css";
import "./WelcomePage.css";

export default function WelcomePage() {
    return (
        <div className="WelcomePage">
            <div className="WelcomePage-container">
                <h1>welcome to h@b!</h1>
                <p className="WelcomePage-sub">
                    Hack@Brown is all about learning. Spend a weekend with 500
                    other students to take a step out of your comfort zone!
                </p>
                <p className="WelcomePage-sub">
                    Whether you're building your first website or dabbling in
                    Photoshop, Hack@Brown is for individuals of all backgrounds.
                </p>
                <p className="WelcomePage-sub">
                    This year, our theme is "Campfire." Take inspiration from
                    your passions and experiment with your wildest ideas. Build
                    away!
                </p>
            </div>
        </div>
    );
}
