import "../../globalstyles.css"
import "../../App.css"
import "./TeamPage.css";
import {Dimensions} from 'react-native';
import ImageResizeMode from 'react-native';
var width = Dimensions.get('window').width; 
var height = Dimensions.get('window').height; 
const team = require('../../assets/team.jpg');

export default function TeamPage() {
    return (
        <div className='TeamPage'>

            <div className="TeamPage-Text">

                <h1>team</h1>

                <img alt='team'  className="image"
                src={String(team)} />

            </div>
        </div >

    )
}
