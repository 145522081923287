import { useGLTF } from "@react-three/drei";

export function BrownGhost(props) {
  const { nodes, materials } = useGLTF("/models/ghost2.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[0.9, 4.17, -0.07]}
        rotation={[0, -0.28, 0]}
        scale={0.02}
      >
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_2002.geometry}
          material={materials["Material.013"]}
          position={[-35.44, 157.26, 45.33]}
          rotation={[0, -0.4, -1.53]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_4002.geometry}
          material={materials["Material.013"]}
          position={[-1.93, 141.75, 66.62]}
          rotation={[0, 0.78, 0]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_5002.geometry}
          material={materials["Material.014"]}
          position={[-119.23, 78.55, 28.29]}
          rotation={[-1.35, -0.41, -1.11]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_6002.geometry}
          material={materials["Material.014"]}
          position={[81.57, 7.85, 57.39]}
          rotation={[0, 0.79, 0]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_7001.geometry}
          material={materials["Material.013"]}
          position={[34.75, 160.16, 47.99]}
          rotation={[-0.02, 0.14, -1.53]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere_3004.geometry}
          material={materials["Material.014"]}
          position={[-71.21, 193.63, -21.45]}
          rotation={[0, 0, 0.56]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere_3005.geometry}
          material={materials["Material.014"]}
          position={[71.33, 193.63, -21.45]}
          rotation={[0, 0, 0.56]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere_4002.geometry}
          material={materials["Material.014"]}
          position={[-3.12, 13.19, -0.46]}
          scale={[1, 2.2, 1]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere002.geometry}
          material={materials["Material.015"]}
          position={[-0.82, 124.39, 50.55]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/ghost2.glb");