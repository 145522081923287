import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'

const yellow = new THREE.MeshStandardMaterial({
  color: 0xCF8B34,
})

export function RV2(props) {
  const { nodes, materials } = useGLTF("/models/rv2.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={yellow}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials.Wheels}
        position={[-0.14, -0.53, -0.96]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={nodes.Cube001.material}
        scale={[0.95, 1, 1.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials.Wheels}
        position={[1.14, -0.53, -0.96]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials.Window}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={materials.Window}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials.Window}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={materials.Window}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Car Body"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials.Metal}
        position={[2.04, -0.62, 0.02]}
        rotation={[0, 0, -1.89]}
        scale={0.11}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={materials["Car Body"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials.Metal}
        position={[-0.96, -0.58, 0]}
        rotation={[-3.14, 0, 1.21]}
        scale={[0.11, 0.11, 0.12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={materials.Material}
        scale={[1, 1, 0.97]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={materials.Metal}
        position={[0.13, 0.03, 0]}
        scale={0.95}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002.geometry}
        material={materials.Wheels}
        position={[-0.14, -0.53, 0.97]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={materials.Wheels}
        position={[1.14, -0.53, 0.97]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        material={materials.Lights}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={materials.Lights}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012.geometry}
        material={nodes.Cube012.material}
      />
    </group>
  );
}

useGLTF.preload("/models/rv2.glb");

