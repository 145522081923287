import React from 'react'
import "./FAQItem.css"

interface FAQProps {
    question: string;
    info: React.ReactNode;
}

export default function FAQItem(props : FAQProps) {
  return (
    <div className='faq-item'>
        <div className='question'> {props.question}</div>
        <div className='info'> {props.info}</div>
    </div>
  )
}
