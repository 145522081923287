import React from 'react'
import "./ItineraryItem.css"

interface ItineraryProps {
    time: string,
    title: string,
    location: string;
}

export default function ItineraryItem(props : ItineraryProps) {
  return (
    <div className='itinerary-item'>
        <div className='time'> {props.time}</div>
        <div className='title'>{props.title}</div>
        <div className='location'>{props.location}</div>
    </div>
  )
}
