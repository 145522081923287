import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'

export function Clover(props) {
  const { nodes } = useGLTF("/models/clover.glb");
  const stdmat = new THREE.MeshStandardMaterial({
    color: 0x743800,
    // ToneMapping: false
  })
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={stdmat}
        position={[-0.5, -2.13, -0.11]}
        scale={-0.36}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roundcube009.geometry}
        material={stdmat}
        position={[2.92, 3.19, -1.46]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roundcube001.geometry}
        material={stdmat}
        position={[-1.74, 3.19, 1.23]}
        rotation={[-Math.PI, 0.26, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roundcube002.geometry}
        material={stdmat}
        position={[-0.44, 3.19, -2.27]}
        rotation={[0, 1.39, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roundcube003.geometry}
        material={stdmat}
        position={[1.95, 3.41, 2.64]}
        rotation={[3.04, -1.33, -Math.PI]}
      />
    </group>
  );
}

useGLTF.preload("/models/clover.glb");