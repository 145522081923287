import React from 'react'

interface StarterPackProps {
    title: string,
    link: string,
    enabled: boolean,
    children?: React.ReactNode;
}

export default function StarterPack(props : StarterPackProps) {
  return (
    <div id="pack-div">
      <a id="pack-link" href={props.enabled ? props.link : "javascript:void(0)"}>
        
        <div id="pack-title">{props.title}</div>
        <div id="desc">
            {props.children}
        </div>
        <div id="minified-link">
          {props.enabled ? getMinifiedURL(props.link) : "coming soon..."}
        </div>
      </a>
    </div>
  )
}

const getMinifiedURL = (fullURL : string) => {
  const withoutHttps = fullURL.split("//")[1];
  const hostname = withoutHttps.split("/")[0];
  return "www." + hostname;
}
