import { useGLTF } from "@react-three/drei";

export function Cottage(props) {
  const { nodes, materials } = useGLTF("/models/cottage.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.Cube.geometry}
        material={materials.Material}
        position={[0, -0.02, 0]}
        scale={[1, 0.85, 1]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube003.geometry}
        material={materials["Material.003"]}
        position={[-0.91, -0.02, -0.9]}
        scale={[-0.15, 0.85, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Material.003"]}
        position={[-0.9, -0.02, 0.89]}
        scale={[-0.15, 0.85, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube002.geometry}
        material={materials["Material.003"]}
        position={[0.91, -0.02, 0.9]}
        scale={[-0.15, 0.85, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube004.geometry}
        material={materials["Material.003"]}
        position={[0.89, -0.02, -0.88]}
        scale={[-0.15, 0.85, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube005.geometry}
        material={materials["Material.003"]}
        position={[0.01, -0.67, 0.9]}
        rotation={[0, 0, -1.58]}
        scale={[-0.2, 0.78, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Material.001"]}
        position={[0.58, 1.05, 0]}
        rotation={[0, 0, 0.87]}
        scale={[0.18, 0.9, 1.09]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube008.geometry}
        material={materials["Material.003"]}
        position={[0, 0.09, 1.01]}
        rotation={[0, 0, -1.58]}
        scale={[-0.31, 0.34, -0.07]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube009.geometry}
        material={materials["Material.009"]}
        position={[0.01, 0.1, 0.86]}
        rotation={[0, 0, -1.58]}
        scale={[-0.2, 0.25, -0.16]}
      />
      <mesh
        castShadow
        geometry={nodes.Cube010.geometry}
        material={materials["Material.003"]}
        position={[0.03, 0.8, 0.88]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.12, 0.85, 0.16]}
      />
    </group>
  );
}

useGLTF.preload("/models/cottage.glb");