import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Campfire(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/campfire.glb");
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Fire1" />
        <mesh
          name="Roundcube002"
          castShadow
          receiveShadow
          geometry={nodes.Roundcube002.geometry}
          material={materials["Fire.1"]}
          position={[0, -0.03, 0.41]}
          rotation={[0.12, 0, 0]}
          scale={0.76}
        />
        <mesh
          name="Icosphere"
          castShadow
          receiveShadow
          geometry={nodes.Icosphere.geometry}
          material={materials["Fire.3"]}
          position={[0, -2.01, 0]}
          scale={-0.11}
        />
        <mesh
          name="Cylinder"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials.Log}
          rotation={[0, -Math.PI / 4, -Math.PI / 2]}
          scale={[0.37, 1.49, 0.37]}
        />
        <mesh
          name="Cylinder001"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials.Log}
          rotation={[0, Math.PI / 4, -Math.PI / 2]}
          scale={[0.37, 1.49, 0.37]}
        />
        <mesh
          name="Roundcube001"
          castShadow
          receiveShadow
          geometry={nodes.Roundcube001.geometry}
          material={materials["Fire.002"]}
          position={[0, -0.23, 0.62]}
          rotation={[0.12, 0, 0]}
          scale={0.76}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/campfire.glb");
