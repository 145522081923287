import { Clone, useGLTF } from "@react-three/drei";
import * as THREE from 'three'

// position={[-3,-0.2,2]} scale={0.3}

export function RoundTree(props) {
  const { nodes } = useGLTF("/models/tree3.glb");

  const stdmat = new THREE.MeshStandardMaterial({
    color: 0x743800,
    ToneMapping: false
  })
  nodes.Roundcube.material = stdmat
  nodes.Cylinder001.material = stdmat

  return (
    <group {...props} dispose={null}>
      <group position={[-3,-0.2,2]} scale={0.3}>
        <Clone castShadow receiveShadow object={nodes.Roundcube}/>
        <Clone castShadow receiveShadow object={nodes.Cylinder001}/>
      </group>
      <group position={[2.,-0.45,2]} scale={0.25}>
        <Clone castShadow receiveShadow object={nodes.Roundcube}/>
        <Clone castShadow receiveShadow object={nodes.Cylinder001}/>
      </group>

      //model4
      <group position={[23,-0.45,3]} scale={0.25}>
        <Clone castShadow receiveShadow object={nodes.Roundcube}/>
        <Clone castShadow receiveShadow object={nodes.Cylinder001}/>
      </group>

      //model5
      <group position={[32.8,-0.6,4]} scale={0.15}>
        <Clone castShadow receiveShadow object={nodes.Roundcube}/>
        <Clone castShadow receiveShadow object={nodes.Cylinder001}/>
      </group>
    </group>
  );
}

useGLTF.preload("/models/tree3.glb");