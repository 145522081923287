import './Three.css';
import { Canvas } from '@react-three/fiber';
import { StrictMode } from 'react';
import * as THREE from 'three';
import { Perf } from 'r3f-perf';
import Scene from './Scene';

export default function Three({
    setShowNav,
    showGraphics,
    setShowSetGraphicsButton,
}) {
    return (
        <div id="Three">
            <StrictMode>
                <Canvas shadows camera={{ position: [0, -0.3, 10], fov: 25 }}>
                    {/* <Perf position='top-left'/> */}
                    <Scene
                        setShowNav={setShowNav}
                        showGraphics={showGraphics}
                        setShowSetGraphicsButton={setShowSetGraphicsButton}
                    />
                </Canvas>
            </StrictMode>
        </div>
    );
}
