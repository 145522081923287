import { useGLTF } from "@react-three/drei";

export function Tent(props) {
  const { nodes, materials } = useGLTF("/models/tent.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["Material.013"]}
        position={[1.58, 2.02, 0]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[2, 0.08, 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Material.010"]}
        position={[0, 1.54, 1.08]}
        rotation={[1, 0, 0]}
        scale={[2, 0.09, 1.94]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials["Material.011"]}
        position={[0, 1.54, -1.08]}
        rotation={[-1, 0, 0]}
        scale={[2, 0.09, 1.94]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.008"]}
        position={[1.75, 0.04, 2.31]}
        scale={[0.08, 0.17, 0.08]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.007"]}
        position={[1.75, 0.04, -2.3]}
        scale={[0.08, 0.17, 0.08]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002.geometry}
        material={nodes.Cylinder002.material}
        position={[-2.07, 0.04, -2.3]}
        scale={[0.03, 0.17, 0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={materials["Material.006"]}
        position={[-2.07, 0.04, 2.31]}
        scale={[0.08, 0.17, 0.08]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={materials["Material.009"]}
        scale={[2, 0.08, 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials["Material.012"]}
        position={[-1.72, 2.02, 0]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[2, 0.08, 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004.geometry}
        material={materials["Material.005"]}
        position={[-2.07, 0.04, -2.3]}
        scale={[0.08, 0.17, 0.08]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder005.geometry}
        material={materials["Material.001"]}
        position={[-1.99, 1.7, -1.14]}
        rotation={[0.67, -0.04, -0.05]}
        scale={[0.03, 1.93, 0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder006.geometry}
        material={materials["Material.002"]}
        position={[-1.99, 1.7, 1.12]}
        rotation={[-0.67, 0.04, 3.09]}
        scale={[-0.03, -1.93, -0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder007.geometry}
        material={materials["Material.003"]}
        position={[1.84, 1.7, 1.12]}
        rotation={[-0.67, 0.04, 3.09]}
        scale={[-0.03, -1.93, -0.03]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder008.geometry}
        material={materials["Material.004"]}
        position={[1.84, 1.7, -1.14]}
        rotation={[0.67, -0.04, -0.05]}
        scale={[0.03, 1.93, 0.03]}
      />
    </group>
  );
}

useGLTF.preload("/models/tent.gltf");


