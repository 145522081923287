import "./Navbar.css";
function Dropdown() {

  const executeScroll = (elemID: string) => {
    document.getElementById(elemID)?.scrollIntoView({ behavior: "smooth", inline: "start" });
  }

  return (
    <div className='hamburger-links'>
      <div className='navlink nav-item'>
        <a onClick={() => executeScroll('about-scroll')}>about</a>
      </div>
      <div className='navlink nav-item'>
        <a onClick={() => executeScroll('itinerary-scroll')}>itinerary</a>
      </div>

      <div className='navlink nav-item'>
        <a onClick={() => executeScroll('faq-scroll')}>faq</a>
      </div>
      <div className='nav-starter-packs nav-item'>
        <a onClick={() => executeScroll('starter-packs')}>starter packs</a>
      </div>
      <div className='nav-sponsors nav-item'>
        <a onClick={() => executeScroll('sponsors')}>sponsors</a>
      </div>

      <div className='nav-join-login nav-item'> <a
      href="https://docs.google.com/presentation/d/1Z7LFcnVUNw6qyDycJNLax8eUCRqxIlhFqi8RQd4IM7c/edit?usp=sharing" target="_blank"
      >impact report</a>
      </div>
    </div>
  )
}

export default Dropdown;