import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'
const stdmat = new THREE.MeshLambertMaterial({
  color: new THREE.Color( 0.06, 0.02, 0.003 ),
  reflectivity: 0
})

export function Ground(props) {
  const { nodes } = useGLTF("/models/ground.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={stdmat}
        position={[0, 1.03, 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/ground.glb");