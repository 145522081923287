import React, { useState, useEffect } from 'react';
import '../../globalstyles.css';
import '../../App.css';
import './StarterPacksPage.css';
import StarterPack from './StarterPack';
import arrowIcon from './images/arrow.png';

const released = false;

export default function StarterPacksPage() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const windowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', windowResize);
    });
    return (
        <>
            <div id="starter-packs">
                <div id="title">
                    <h1>starter packs</h1>
                    <a
                        id="last-link"
                        href="https://hackatbrown2022.devpost.com/?ref_feature=challenge&ref_medium=discover"
                    >
                        <div id="link-label">last year's winners</div>
                        <img src={arrowIcon} alt="arrow icon"></img>
                    </a>
                </div>
                <div id="nb-div">
                    *the starter packs will be released closer to the
                    hackathon's start date. stay tuned!
                </div>
                <div id="sp-table">
                    <div id="sp-col">
                        <StarterPack
                            title="React Starter Pack"
                            link="https://github.com/hackatbrown/react-starter"
                            enabled={released}
                        >
                            A brief guide to the fundamentals of React, a
                            popular Javascript Library used to construct
                            high-quality UIs.
                        </StarterPack>
                        <StarterPack
                            title="Discord Bot Starter Pack"
                            link="https://github.com/hackatbrown/discordbot-starter"
                            enabled={released}
                        >
                            A brief introduction to building a Discord bot,
                            capable of interacting with users through text and
                            voice chat!
                        </StarterPack>
                        <StarterPack
                            title="Docker Starter Pack"
                            link="https://github.com/hackatbrown/docker-starter"
                            enabled={released}
                        >
                            A quick introduction to creating Docker
                            containers/images, Dockerfiles, and deploying
                            Dockerized applications.
                        </StarterPack>
                        <StarterPack
                            title="Unity Starter Pack"
                            link="https://github.com/hackatbrown/unity-starter"
                            enabled={released}
                        >
                            Get started with building a game with Unity!
                        </StarterPack>
                        <StarterPack
                            title="Cloud Starter Pack"
                            link="https://google.com/404"
                            enabled={released}
                        >
                            A basic introduction to a couple of cloud services
                            (S3, Fargate, Lambda) provided by Amazon Web
                            Services and how to use them.
                        </StarterPack>
                        <StarterPack
                            title="CSS Starter Pack"
                            link="https://github.com/hackatbrown/css-starter"
                            enabled={released}
                        >
                            A beginners guide to everything CSS! Includes a
                            brief introduction to CSS, and interactive demo, and
                            additional readings, resources, and guides. No prior
                            CSS experience required.
                        </StarterPack>
                    </div>
                </div>
            </div>
        </>
    );
}
