import { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Bear(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/bear.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Light"
          position={[5.47, -1, -0.21]}
          rotation={[1.89, 0.88, -2.05]}
        />
        <group
          name="Camera"
          position={[8.68, 0.12, -2.97]}
          rotation={[Math.PI / 2, 0, -1.92]}
        />
        <group
          name="Light001"
          position={[-4.9, 2.64, -2.44]}
          rotation={[1.89, 0.88, -2.05]}
        />
        <group
          name="Light002"
          position={[0.15, 0.98, -5.96]}
          rotation={[1.89, 0.88, -2.05]}
        />
        <mesh
          name="Cylinder001_clean"
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001_clean.geometry}
          material={materials["Material.001"]}
          rotation={[0, 0, -Math.PI / 2]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/bear.glb");
