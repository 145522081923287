import React, { useState } from 'react';
import '../../globalstyles.css';
import '../../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoImg from '../../assets/Frame.svg';
import mlhLogoImg from '../../assets/mlh-logo-black.svg';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Navbar.css';
import { exec } from 'child_process';
import Hamburger from './Hamburger';
import Dropdown from './Dropdown';
//import PropTypes from "prop-types";

interface NavbarProps {
    alt: boolean;
}

// type NavbarState = {
//     user: any;
// };

// export default class Navbar extends React.Component<NavbarProps,NavbarState>{
//     constructor(props: NavbarProps) {
//         super(props);
//         this.state = {
//             user: null
//         };
//     }

// //display login/join or dashboard button
// if (this.state.user != null) {
//     // if true, user is logged in
//     button = (
//         <button
//             onClick={
//                 //logout()
//             }
//             className="toolbar-signin"
//         >
//             <p>Log out</p>
//         </button>
//     );
// }

function Navbar({ alt }: NavbarProps) {
    const executeScroll = (elemID: string) => {
        document
            .getElementById(elemID)
            ?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    };

    const [ham, setHam] = useState(false);
    const toggleHam = () => {
        console.log('ham is clicked and equals', ham);
        setHam(!ham);
    };

    return (
        <div className={`navbar ${alt && 'alt-color'}`}>
            <div className="hammie">
                <div className={`navbar-logo ${alt && 'alt-color'}`}>
                    <img
                        id="navbar-logo-img"
                        src={logoImg}
                        onClick={() => executeScroll('landing-scroll')}
                    ></img>
                </div>
                <div className="ham" onClick={toggleHam}>
                    <Hamburger />
                </div>
            </div>
            {ham && (
                <div className="navbar-dropdown" onClick={toggleHam}>
                    {' '}
                    <Dropdown />
                </div>
            )}

            <div className="navbar-links">
                <div className="navbar-logo">
                    <img
                        id="navbar-logo-img"
                        src={logoImg}
                        onClick={() => executeScroll('landing-scroll')}
                    ></img>
                </div>
                <div className="nav-about nav-item">
                    <a onClick={() => executeScroll('about-scroll')}>about</a>
                </div>
                <div className="nav-itinerary nav-item">
                    <a onClick={() => executeScroll('itinerary-scroll')}>
                        itinerary
                    </a>
                </div>

                <div className="nav-faq nav-item">
                    <a onClick={() => executeScroll('faq-scroll')}>faq</a>
                </div>
                <div className="nav-starter-packs nav-item">
                    <a onClick={() => executeScroll('starter-packs')}>
                        starter packs
                    </a>
                </div>
                <div className="nav-sponsors nav-item">
                    <a onClick={() => executeScroll('sponsors')}>sponsors</a>
                </div>
                <div className="nav-join-login nav-item">
                    {' '}
                    <a href="https://docs.google.com/presentation/d/1Z7LFcnVUNw6qyDycJNLax8eUCRqxIlhFqi8RQd4IM7c/edit?usp=sharing" target="_blank">
                        impact report
                    </a>
                </div>
            </div>

            <div className="mlh-wrapper">
                <a
                    id="mlh-trust-badge"
                    style={{
                        display: 'block',
                        maxWidth: '100px',
                        minWidth: '20px',
                        position: 'fixed',
                        // right: "50px",
                        top: '0',
                        width: '10%',
                        zIndex: '10000',
                    }}
                    href="https://mlh.io/na?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2023-season&utm_content=yellow"
                    target="_blank"
                >
                    <img
                        src="https://s3.amazonaws.com/logged-assets/trust-badge/2023/mlh-trust-badge-2023-yellow.svg"
                        alt="Major League Hacking 2023 Hackathon Season"
                        style={{ width: '100%' }}
                    />
                </a>
            </div>
        </div>
    );
}

export default Navbar;
