import { Clone, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

export function ChristmasTree(props) {
    const { nodes } = useGLTF('/models/tree.glb');

    const stdmat = new THREE.MeshStandardMaterial({
        color: 0x5c2c00,
        ToneMapping: false,
    });
    nodes.Cone.material = stdmat;
    nodes.Cylinder.material = stdmat;

    return (
        <group {...props} dispose={null}>
            {/* <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cone.geometry}
        material={materials["Material.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.001"]}
        position={[0.01, -2.9, 0]}
        scale={0.19}
      /> */}
            <group position={[-2.5, 0.5, 1]} scale={0.3}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
            <group position={[1.5, 0.5, 3.5]} scale={0.3}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
            <group position={[3, 0, 1]} scale={0.2}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
            <group position={[3.2, 0, -2]} scale={0.2}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
            //model3
            <group position={[13.6, 0, 5]} scale={0.2}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
            //model5
            <group position={[32.4, -0.15, 5.5]} scale={0.17}>
                <Clone object={nodes.Cone} castShadow receiveShadow />
                <Clone object={nodes.Cylinder} castShadow receiveShadow />
            </group>
        </group>
    );
}

useGLTF.preload('/models/tree.glb');
