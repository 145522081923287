import React from 'react'
import "../../globalstyles.css"
import "../../App.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Itinerary.css";
import ItineraryItem from './component/ItineraryItem';
import arrow from "./component/arrow.png";

export default function ItineraryPage() {
  return (

    <div className='itinerary' id="itinerary-scroll">

        
        <div className='itinerary-container'>
            <div className='header-class'>
                <h1>itinerary  </h1>
                <button type="button" className="btn btn-outline-success"> 
                    <a 
                        href="https://calendar.google.com/calendar/embed?src=c_7c17b95a94bb1a4a9b9f068b832272adde9a18b755f62414b7a38afbc7ea7a42%40group.calendar.google.com&ctz=America%2FLos_Angeles"
                        target="_blank"
                        rel="noopener noreferrer"> 
                                
                        gcal {'   '}
                        <img src={arrow} style={{height:"15px", width:"15px", display:"inline-block"}}></img>
                        
                    </a>
                </button>
            </div>

            <div className='parent'>
                <div className='child-saturday'>
                    <h2>
                        Saturday
                    </h2>
                    <div className='itinerary-items'>
                        <ItineraryItem 
                            time="9:00 AM - 10:00 AM"
                            title='Check-In'
                            location='Friedman Hall'
                        />
                        <ItineraryItem 
                            time="10:00 AM - 11:00 AM"
                            title='Team Forming'
                            location='Friedman 108'
                        />
                        <ItineraryItem 
                            time="11:00 AM - 12:00 PM"
                            title='Kick-off'
                            location='Salomon DECI'
                        />
                        <ItineraryItem 
                            time="12:00 PM - 1:00 PM"
                            title='Lunch'
                            location='Sayles 104'
                        />
                        <ItineraryItem 
                            time="1:00 PM - 2:00 PM"
                            title='Sponsorship Fair'
                            location='Sayles Auditorium'
                        />
                        <ItineraryItem 
                            time="2:00 PM - 3:00 PM"
                            title='Controlling the Fire: Socially Responsible Computing Panel'
                            location='Friedman 102'
                        />
                        <ItineraryItem 
                            time="2:30 PM - 3:00 PM"
                            title='Crash Course: HTML/CSS'
                            location='Salomon 001'
                        />
                        <ItineraryItem 
                            time="3:00 PM - 4:00 PM"
                            title='Workshop: Intro to React'
                            location='Salomon 001'
                        />
                        <ItineraryItem 
                            time="4:00 PM - 5:00 PM"
                            title='Rekindling with Alumni: Building a Career in Tech Panel'
                            location='Friedman 102'
                        />
                        <ItineraryItem 
                            time="5:00 PM - 6:00 PM"
                            title='Workshop: Intro to Figma'
                            location='Salomon 001'
                        />
                        <ItineraryItem 
                            time="6:00 PM - 7:00 PM"
                            title='Dinner'
                            location='Sayles 104'
                        />
                        <ItineraryItem 
                            time="7:00 PM - 8:00 PM"
                            title='Workshop: Spotify Webscraping with Python'
                            location='Friedman 102'
                        />
                        <ItineraryItem 
                            time="8:00 PM - 9:00 PM"
                            title='Workshop: Web Design with Python and Flask'
                            location='Saloman 001'
                        />
                        <ItineraryItem 
                            time="9:00 PM - 10:00 PM"
                            title='MLH Event: Snyk Cybersecurity Challenge'
                            location='Sayles 105'
                        />
                        <ItineraryItem 
                            time="10:00 PM - 11:00 PM"
                            title='Game Night'
                            location='Sayles 105'
                        />
                    </div>
                </div>

                <div className='child-sunday'>
                    <h2>
                        Sunday
                    </h2>
                    <div className='itinerary-items'>
                        
                        <ItineraryItem 
                                time="10:00 AM - 11:00 AM"
                                title='Brunch'
                                location='Sayles 104'
                        />
                        <ItineraryItem 
                                time="11:00 AM - 12:00 PM"
                                title='Workshop: Ethics in AI'
                                location='Friedman 102'
                        />
                        <ItineraryItem 
                                time="12:00 PM"
                                title='Hacking Ends'
                                location=''
                        />
                        <ItineraryItem 
                                time="12:30 PM"
                                title='Judging Begins'
                                location='Sayles Auditorium'
                        />
                        <ItineraryItem 
                                time="2:00 PM - 3:00 PM"
                                title={'Conscious Camping: Sustainability in Tech Panel'}
                                location='Friedman 102'
                        />
                        <ItineraryItem 
                                time="3:00 PM - 4:30 PM"
                                title='Closing Ceremony'
                                location='Salomon DECI'
                        />

                    </div>                   
                </div>
            </div>
       
        </div>
    </div>
  
  )
}
