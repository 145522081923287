import React from 'react'
import "../../globalstyles.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./FooterPage.css";

export default function FooterPage() {
    return (
        <div className="footer">
            <div className="header">
                <h4> Hack@Brown 2023:</h4>
                <h1> campfire </h1>
            </div>
            <Container className='position'>
                <Row className='footer-group'>
                    <Col sm={3} lg={4}>
                        <div className='footer-chunk'>
                            <h5>Socials</h5>
                            <p className='text'>
                                <a

                                    href="https://www.instagram.com/hackatbrown/"
                                    target="_blank"
                                >
                                    Instagram
                                </a>
                            </p>
                            <p className='text'>
                                <a

                                    href="https://www.facebook.com/hackatbrown/"
                                    target="_blank"
                                >
                                    Facebook
                                </a>
                            </p>
                            <p className='text'>
                                <a

                                    href="https://twitter.com/hackatbrown/"
                                    target="_blank"
                                >
                                    Twitter
                                </a>
                            </p>
                            <p className='text'>
                                <a

                                    href="https://medium.com/hack-at-brown"
                                    target="_blank"
                                >
                                    Medium
                                </a>
                            </p>
                        </div>
                    </Col>
                    <Col sm={3} lg={7}>
                        <div className='footer-chunk'>
                            <h5>Previous</h5>
                            <p className='text'>
                                <a

                                    href="https://2022.hackatbrown.org/"
                                    target="_blank"
                                >
                                    Hack@Brown 2022
                                </a>
                            </p>
                            <p className='text'>
                                <a

                                    href="https://2021.hackatbrown.org/"
                                    target="_blank"
                                >
                                    Hack@Brown 2021
                                </a>
                            </p>
                            <p className='text'>
                                <a

                                    href="https://2020.hackatbrown.org/"
                                    target="_blank"
                                >
                                    Hack@Brown 2020
                                </a>
                            </p>
                            
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className='links'>
                <h4> Links </h4>
                <p className='text' >
                    <a href="/">
                        Landing Page
                    </a>
                </p>
            </div>

            <div className='message'>
                <p className='text'>
                    Special thanks to the Brown Computer Science
                    <br />
                    Department and Brown HCRI!
                </p>
                <p className='subtitle'>
                    <a href='https://static.mlh.io/docs/mlh-code-of-conduct.pdf'
                        target="_blank"
                    >
                        Major League Hacking Code of Conduct
                    </a>
                </p>


            </div>
        </div>
    )
}
