import { Suspense } from "react"
import { WhiteGhost } from "./3DObjects/WhiteGhost"
import { BrownGhost } from "./3DObjects/BrownGhost"
import { Ground } from "./3DObjects/Ground"
import { Bench } from "./3DObjects/Bench"

import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import * as THREE from 'three'

const whiteGhostCenter = new THREE.Vector3(6, -1, -1)
const brownGhostCenter = new THREE.Vector3(7.7, -0.7, 0.5)
const whitePos = new THREE.Vector3()
const brownPos = new THREE.Vector3()
let angle = 0;
const rotMag = 0.1 

export default function Model()
{
    const white = useRef()
    const brown = useRef()
    useFrame((state, delta) =>
      {
        angle += delta
        angle %= 6.28319
        const additionWhite = new THREE.Vector3(Math.cos(angle)*rotMag, Math.sin(angle)*rotMag, 0)
        const additionBrown = new THREE.Vector3(Math.cos(angle+1)*rotMag, Math.sin(angle+1)*rotMag, 0)
        white.current.position.copy(whitePos.addVectors(whiteGhostCenter, additionWhite))
        brown.current.position.copy(brownPos.addVectors(brownGhostCenter, additionBrown))
        // console.log(white.current.position)
      })
    return (
        <Suspense>
            <group ref={white} position={whiteGhostCenter}><WhiteGhost scale={0.2}/></group>
            <group ref={brown} position={brownGhostCenter}><BrownGhost scale={0.2}/></group>
            <Ground position={[4.7, -0.97, 2.5]} scale={0.3} rotation-z={3.14159}/>
            <Bench position={[4, -1., 4]} scale={0.15} rotation-y={0.5}/>
        </Suspense>
    )
}