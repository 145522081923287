import "../../globalstyles.css"
import "../../App.css"
import "./Sponsorship.css";
import nelson from "./logos/nelson.png"
import reliable from "./logos/reliable.gif"
import warp from "./logos/warp_logo.png"
import wolfram from "./logos/wolfram.png"
import splitwise from "./logos/splitwise.png"
import stickermule from "./logos/sticker-mule.png"
import intuscare from "./logos/intus-care.png"
import flatiron from "./logos/flatiron.png"

export default function SponsorshipPage() {
    return (
        <div className='SponsorshipPage' id="sponsors">

            <div className="SponsorshipPage-Text">

                <h1>sponsors</h1>

                <div className="sponsor-items">
                    <div className="sponsors-giga sponsors-section">
                        <h2 className="sponsors-name">Giga</h2>
                        <div className="sponsors-img">
                            <a
                                href="https://reliable.co/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="sponsors-img-giga"
                                    src={reliable}
                                    alt="reliable"
                                    // style={{ width: "20%" }}
                                ></img>
                            </a>
                        </div>
                    </div>
                    <div className="two-columns">
                        <div className="sponsors-kilo sponsors-section">
                            <h2 className="sponsors-name">Mega</h2>
                            <div className="sponsors-img">
                                <a
                                    href="https://www.splitwise.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="sponsors-img-kilo"
                                        src={splitwise}
                                        alt="Splitwise"
                                    ></img>
                                </a>

                                <a
                                    href="https://www.intuscare.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="sponsors-img-kilo"
                                        src={intuscare}
                                        alt="Intus Care"
                                    >

                                    </img>

                                </a>
                            </div>
                        </div>
                        <div className="sponsors-kilo sponsors-section">
                            <h2 className="sponsors-name">Kilo</h2>
                            <div className="sponsors-img">
                                <a
                                    href="https://www.warp.dev/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="sponsors-img-kilo"
                                        src={warp}
                                        alt="Warp"
                                    ></img>
                                </a>

                                <a
                                    href="https://entrepreneurship.brown.edu/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="sponsors-img-kilo"
                                        src={nelson}
                                        alt="Nelson Center"
                                    ></img>
                                </a>

                                <a
                                    href="https://flatiron.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="sponsors-img-kilo"
                                        src={flatiron}
                                        alt="Flatiron"
                                    ></img>
                                </a>
                            </div>

                        </div>
                    </div>



                    <div className="sponsors-partners sponsors-section">
                        <p>In partnership with</p>
                        <a
                            href="https://www.wolfram.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="sponsors-img-micro"
                                style={{
                                    width: "15%",
                                }}
                                src={wolfram}
                                alt="wolfram"
                            ></img>
                        </a>
                        <a
                            href="https://mule.to/p3j1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                className="sponsors-img-micro"
                                style={{
                                    width: "15%",
                                }}
                                src={stickermule}
                                alt="stickermule"
                            ></img>
                        </a>
                    </div>
                </div>

                <div className="sponsors-text">
                    Interested in sponsoring Hack@Brown? Reach out to us on{" "}
                    <a href="mailto:sponsor@hackatbrown.org">
                        <b>sponsor@hackatbrown.org</b>
                    </a>
                    !
                </div>
            </div>
        </div >

    )
}
