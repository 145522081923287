import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './globalstyles.css';
import LandingPageText from './components/LandingPage/LandingPageText';
import AboutPage from './components/AboutPage/AboutPage';
import ItineraryPage from './components/ItineraryPage/ItineraryPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import StarterPacksPage from './components/StarterPacksPage/StarterPacksPage';
import FooterPage from './components/FooterPage/FooterPage';

import FAQPageText from './components/FAQPage/FAQPageText';
import Navbar from './components/Navbar/Navbar';
import WelcomePage from './components/WelcomePage/WelcomePage';

import Three from './components/Three/Three';
import TeamPage from './components/TeamPage/TeamPage';
import SponsorshipPage from './components/SponsorshipPage/Sponsorship';

function App() {
    // 0 -> light text, 1 -> dark text, 2 -> hide
    const [showNavState, setShowNav] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    const [showGraphics, setShowGraphics] = useState(true);
    const [showSetGraphicsButton, setShowSetGraphicsButton] = useState(true);

    const three = (
        <Three
            setShowNav={setShowNav}
            showGraphics={showGraphics}
            setShowSetGraphicsButton={setShowSetGraphicsButton}
        />
    );
    const none = <div />;
    const mobile = 900;

    useEffect(() => {
        const windowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', windowResize);
    });

    return (
        <div className="App">
            {width < mobile ? none : three}
            {/* <Three
                setShowNav={setShowNav}
                showGraphics={showGraphics}
                setShowSetGraphicsButton={setShowSetGraphicsButton}
            /> */}
            <div className="horizontal">
                {showNavState !== 2 && <Navbar alt={showNavState === 1} />}

                <div className="child">
                    <LandingPageText
                        setShowGraphics={setShowGraphics}
                        showGraphics={showGraphics}
                        showSetGraphicsButton={showSetGraphicsButton}
                    />
                </div>
                <div className="child">
                    <WelcomePage />
                </div>
                <div className="child">
                    <AboutPage />
                </div>

                <div className="child">
                    <ItineraryPage />
                </div>
                <div className="child">
                    <FAQPageText />
                </div>
                <div className="child">
                    <StarterPacksPage />
                </div>
                <div className="child">
                    <SponsorshipPage />
                </div>
                <div className="child">
                    <TeamPage />
                </div>
                <div>
                    <FooterPage />
                </div>
            </div>
        </div>
    );
}

export default App;
