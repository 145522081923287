import { Suspense } from 'react';
import { Bear } from './3DObjects/Bear';

export default function Model3() {
    return (
        <Suspense>
            <Bear scale={0.3} position={[12, -0.5, 2]} />
        </Suspense>
    );
}
