import { useGLTF } from "@react-three/drei";
import * as THREE from 'three'

export function Moon(props) {
  const { nodes} = useGLTF("/models/moon.glb");
  const stdmat = new THREE.MeshStandardMaterial({
    color: 0xD19C52,
    // ToneMapping: false
  })

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roundcube.geometry}
        material={stdmat}
      />
    </group>
  );
}

useGLTF.preload("/models/moon.glb");
