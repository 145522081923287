import { useGLTF} from "@react-three/drei";
import * as THREE from 'three'

const stdmat = new THREE.MeshStandardMaterial({
  color: 0x6F6F6F,
})

export function WhiteGhost(props) {

  const { nodes, materials } = useGLTF("/models/ghost1.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[3.73, 5.8, 7.04]} scale={0.02}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_2003.geometry}
          material={materials["Material.013"]}
          position={[-37.65, 157.26, 50.55]}
          rotation={[0, -0.4, 0.15]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_3001.geometry}
          material={materials["Material.013"]}
          position={[35.93, 157.26, 50.55]}
          rotation={[0.12, 0.39, -0.38]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube_4003.geometry}
          material={materials["Material.013"]}
          position={[-1.93, 141.75, 66.62]}
          rotation={[0, 0.78, 0]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_5003.geometry}
          material={stdmat}
          position={[-98.77, 19.6, 57.79]}
          rotation={[0, -0.66, 0]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Cube_6003.geometry}
          material={stdmat}
          position={[83.06, 19.6, 61.31]}
          rotation={[0, 0.42, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere_3006.geometry}
          material={stdmat}
          position={[-71.21, 193.63, -21.45]}
          rotation={[0, 0, 0.56]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere_3007.geometry}
          material={stdmat}
          position={[71.33, 193.63, -21.45]}
          rotation={[0, 0, 0.56]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere_4003.geometry}
          material={stdmat}
          position={[-3.12, 13.19, -0.46]}
          scale={[1, 2.2, 1]}
        />
        <mesh
          // castShadow
          receiveShadow
          geometry={nodes.Sphere003.geometry}
          material={stdmat}
          position={[-0.82, 124.39, 50.55]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/ghost1.glb");


