import { Suspense } from 'react';
import { Tent } from './3DObjects/Tent';

export default function Model4() {
    return (
        <Suspense>
            <Tent scale={0.3} position={[22.3, -1, 3]} rotation-y={1.2} />
        </Suspense>
    );
}
