import { Suspense } from "react"

import { Moon } from "./3DObjects/Moon"
import { Cottage } from "./3DObjects/Cottage"
import { Campfire } from "./3DObjects/Campfire"
import { Clover } from "./3DObjects/Clover"

export default function Model1()
{
    return (
        <Suspense>
            <Moon position={[1.5,1,0]} scale={0.35}/>
            <Cottage position={[0.5,-0.55,2]} scale={0.5} rotation-y={-0.7}/>
            <Campfire position={[0.39,-0.95,4]} scale={0.15}/>
            <Clover position={[1.3,-1.02,3.5]} scale={0.03} rotation-y={4}/>
        </Suspense>
    );
}
