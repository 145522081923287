import { Suspense } from 'react';
import { RV2 } from './3DObjects/RV2';

export default function Model5() {
    return (
        <Suspense>
            <RV2 position={[33, -0.63, 4.2]} scale={0.4} rotation-y={-1} />
        </Suspense>
    );
}
